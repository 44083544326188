<template>
  <component-window
      :title="orderTitle"
      lock-name="metaorder"
      :watch-object="editOrder"
      :isWatch="lockerIsWatch"
      :show-refresh-button="true"
      @click-refresh-button="onReloadOrder"
      ref="compWindow"
      @close="onBtnCancelClick"
      :maximize="!isTabView"
      :is-test="editOrder && editOrder.isTest"
      add-style="overflow-x:hidden"
  >
    <el-tabs
        :value="activeTabName"
        style="display:flex;flex-direction: column;width: 100%; height:98%"
        type="border-card"
        @tab-click="onClickTabHeader"
        ref="tabs"
        slot-scope="{lockerState}"
    >

      <el-tab-pane label="Поля" name="fields" style="height: 100%">
        <div style="display:flex;height:100%;width: 100%">
          <div style="display: flex;flex: 1 0; width: 100%;padding-right: 10px;overflow-y: auto ">
            <el-form label-width="120px" style="width:100%" :model="editOrder" ref="editForm" :rules="rules">
              <el-form-item label="Номер">
                <div style="display: flex; flex-wrap: nowrap;justify-content: space-between">
                  <div style="display: flex;flex-wrap: nowrap;align-items: center">
                    <el-input readonly :value="editOrder.number || 'Еще нет номера - заказ не сохранен'" style="width:150px"></el-input>
                    <el-form-item
                        v-if="editOrder.client && editOrder.client.country && editOrder.client.countryId !== 1"
                        label-width="150px" label="Номер у заказчика" style="margin-bottom: 0">
                      <el-input v-model="editOrder.clientOrderNumber"></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <div style="display: flex;">

                      <el-form-item label="Замена по рекламации" label-width="150px" style="margin-bottom: 0">
                        <el-switch
                            :disabled="!canSetReclamation"
                            @change="onChangeReclamation" :value="orderIsReclamation"/>
                      </el-form-item>
                      <el-form-item label="Стоковый заказ" style="margin-bottom: 0">
                        <el-switch
                            :disabled="editOrder.orders.some(o => o.type !== 'pdt') || editOrder.payType !== 'fromInvoice'"
                            v-model="editOrder.isStock"/>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </el-form-item>

              <el-form-item label="Заказчик" prop="user" ref="userSelector">
                <my-selector
                    type="user"
                    v-model="editOrder.user"
                    placeholder=""
                    :buttons='!user'
                    @edit="onEditUser(editOrder.user.id)"
                    @add="onEditUser(0)"
                    :selector="client ? onAutoCompleteUsers : null"
                />
                <div v-if="editOrder.user">
                  <div style="display: flex;justify-content: space-between">
                    <div>
                      <b>Логин:&nbsp;&nbsp;</b><span v-html="editOrder.user.login ? editOrder.user.login : editOrder.user.oldLogin"></span>
                    </div>
                    <div style="display: flex;flex-wrap: nowrap">
                      <my-comment style="align-self: flex-end"
                                  :length="50"
                                  :comment="editOrder.user.importantComment"></my-comment>
                      <i v-if="editOrder.user.isChecked"
                         class="el-icon-circle-check"
                         style="font-weight: bold;color:green; align-self: center;margin-right: 10px;margin-left: 5px"></i>
                    </div>
                  </div>
                  <b>Адреса:&nbsp;&nbsp;</b><span v-html="userMails"></span><br/>
                  <b>Телефоны:&nbsp;&nbsp;</b><span v-html="userPhones"></span>
                </div>
              </el-form-item>
              <el-form-item label="Клиент" prop="client" ref="clientSelector">
                <div style="">
                  <my-selector
                      type="client"
                      v-model="editOrder.client"
                      placeholder=""
                      :buttons='!client'
                      :selector="onAutocompleteClientSearch"
                      @edit="onEditClient(editOrder.client.id)"
                      @add="onEditClient(0)"
                  />
                </div>
                <div v-if="editOrder.client" style="display: flex;justify-content: space-between">
                    <div>
                      <b>Организация:&nbsp;&nbsp;</b><span v-html="clientAffiliation"></span>
                    </div>
                    <div style="display: flex">
                      <my-comment
                          style="align-self: flex-end"
                          :length="50"
                          :comment="editOrder.client.importantComment"></my-comment>
                      <my-client-debt-warning :client="editOrder.client"></my-client-debt-warning>
                      <i v-if="editOrder.client.isCheck"
                         :class="editOrder.client.defaultPayType === 'fromAccount' ? 'el-icon-circle-check' : 'el-icon-check'"
                         style="font-weight: bold;color:green; align-self: center;margin-right: 10px;margin-left: 5px"></i>
                    </div>
                </div>
                <div  style="display: flex">
                  <my-comment
                      style="align-self: flex-end"
                      :length="400"
                      :comment="clientAffiliationComment"></my-comment>
                </div>
              </el-form-item>
              <el-form-item label="Плательщик" prop="billingAffiliation" ref="affiliationSelector">
                  <my-selector
                    type="affiliation"
                    v-model="editOrder.billingAffiliation"
                    placeholder=""
                    :buttons='!!editOrder.client'
                    :selector="onAutocompleteAffiliationSearch"
                    @edit="onEditBillingAffiliation(editOrder.billingAffiliation.id)"
                    @add="onEditBillingAffiliation(0)"
                />
                <div style="display: flex; justify-content: flex-end">
                  <contracts-view :affiliation="editOrder.billingAffiliation"></contracts-view>
                </div>
              </el-form-item>
              <el-form-item v-if="editOrder.userComment && editOrder.userComment.length>0" label="Комментарий заказчика">
                <el-input type="textarea"
                          :readonly="true"
                          :autosize="{ minRows: 0, maxRows: 4}"
                          v-model="editOrder.userComment">
                </el-input>
              </el-form-item>
              <el-form-item label="Комментарий оператора">
                <el-input type="textarea"
                          :autosize="{ minRows: 0, maxRows: 4}"
                          v-model="editOrder.managerComment">
                </el-input>
              </el-form-item>
            </el-form>
          </div>
          <el-container style="flex:0 0 270px;height: 100%">
            <el-header style="padding: 0 0 0 10px; height: auto">
              <el-table
                  :data="ordersView"
                  :showAddButton="false"
                  :default-sort="{prop: 'number'}"
                  style="width: 100%;font-size: 16px;flex: none"
                  :border="true"
                  :row-class-name="summaryRowClassName"
              >
                <el-table-column label="Номер">
                  <template slot-scope="scope">
                    <template v-if="!scope.row.title">
                      <div class="my-link" @click="activeTabName = scope.row.id.toString()">{{ scope.row.number }}</div>
                      {{ $getEnumValue('OrderStateEnum', scope.row.state) }}
                    </template>
                    <template v-else>{{ scope.row.title }}</template>
                  </template>
                </el-table-column>
                <el-table-column :label="`Цена, ${$getEnumValue('CurrencyEnum', editOrder.currency)} `" width="100" property="price">
                  <template slot-scope="scope">
                    <template v-if="!scope.row.title">
                      {{ $roundFmt(calcSubOrderPrice(scope.row)) }}
                    </template>
                    <template v-else>
                      {{ scope.row.value }}
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </el-header>
            <el-main style="display:flex; flex-direction:column; padding: 0 0 0 10px;justify-content: stretch">
              <div v-if="editOrder.orders.some(o => o.previousSum !== null)"
                  style="margin-top: 30px;padding: 5px; border: 1px solid #EBEEF5">
                  Сумма заказа была изменена. Подробнее - <span class="my-link" @click="activeTabName = 'log'">в истории заказа</span>
              </div>
            </el-main>
            <el-footer style="display: flex;justify-content: space-between;height: auto;padding-left: 5px">
            </el-footer>
          </el-container>
        </div>
      </el-tab-pane>
      <el-tab-pane name="delivery" style="height: 100%; overflow-y: auto">
                <template v-slot:label >
                     <span v-html="deliveryHeader()"></span><i class="el-icon-loading" v-if="isDeliveryLoading"></i>
                </template>
        <delivery-tab
            :edit-order="editOrder"
            :deliverys="deliverys"
            :lock-manager="lockerState"
            @change-active-tab="activeTabName=$event"
            @save-order="onBtnSaveClick"
            ref="deliveryTab"
        ></delivery-tab>
      </el-tab-pane>
      <el-tab-pane v-if="!orderIsReclamation"  :label="payTabLabel" name="pays" style="height:100%">
        <div slot="label">
          {{ payTabLabel }}
          <i v-if="editOrder.paidFromAccount" class="el-icon-check" style="color: green; font-weight: bold"></i>
        </div>
        <pay-tab
            :edit-order="editOrder"
            :deliveries="deliverys"
            @account-pay="onBtnAccountPay"
            @save-order="onBtnSaveClick"
        ></pay-tab>
      </el-tab-pane>
      <el-tab-pane :label="fileTabLabel" name="files" style="height:100%">
        <div class="cell" v-if="editOrder.noArchiveUntil" style="display: flex;flex-wrap: nowrap;justify-content: flex-end;margin-top: 10px">
          <div style="padding-right: 5px">Файлы будут убраты в архив</div> <my-date :date="editOrder.noArchiveUntil" />
        </div>
        <file-table v-if="!editOrder.isArchive" :file-type="['userFiles', 'managerFiles', 'purchaseOrder', 'priceQuotation']" :files="editOrder.files" with-types with-date></file-table>
        <div  class="cell" v-if="editOrder.isArchive"  style="display: flex;flex-wrap: nowrap;justify-content: flex-end;margin-top: 10px">
          <my-el-button type="success" @click="restoreArchiveFiles">Достать файлы заказа из архива</my-el-button>
        </div>

      </el-tab-pane>
      <el-tab-pane :label="`Письма (${  editOrder.mails ? editOrder.mails.length : '0' })`" name="Mails" style="height:100%">
        <div style="height:100%;display:flex; flex-direction:column">
          <el-table
              :data="editOrder.mails"
              :default-sort="{prop: 'id', order: 'descending'}"
              style="width: 100%;font-size: 16px"
              height="100%"
              :border="true"
              v-ls-saver:mails
          >
            <el-table-column label="Тема" :resizable="true" prop="subject">
              <template slot-scope="scope">
                <div class="my-link"
                     @click="showMailEditor(scope.row, 'view')"
                     v-html="scope.row.subject"></div>
              </template>
            </el-table-column>
            <el-table-column label="Дата" width="150" :resizable="true">
              <template slot-scope="scope">
                <my-date :date="scope.row.createdAt"/>
              </template>
            </el-table-column>
            <el-table-column label="Кто отправил" width="150px" :resizable="true" prop="managerName"/>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="История заказа" name="log" style="height:100%">
        <div style="height:100%;display:flex; flex-direction:column">
          <div style="display: flex">
          <el-radio-group v-model="stateLogSelector" style="margin-bottom: 10px">
            <el-radio-button
                label=""
                v-if="editOrder.changes && editOrder.changes.length > 0">{{ editOrder.number }}
            </el-radio-button>
            <template v-for="order in editOrder.orders">
              <el-radio-button
                  :label="order.id"
                  :key="'log_' + order.id"
                  v-if="order.stateLog && order.stateLog.length > 0">{{ order.number }}
              </el-radio-button>
            </template>
          </el-radio-group>
          <my-el-button v-if="rawOrder" @click="showRowOrder" type="success">Заказ на сайте</my-el-button>
          </div>
          <el-table v-if="stateLogSelector" :data="orderLog"
                    :default-sort="{prop: 'id', order: 'descending'}"
                    style="width: 100%;font-size: 16px"
                    height="100%"
                    :border="true"
                    v-ls-saver:history
          >
            <el-table-column label="Дата" width="100" :resizable="true">
              <template slot-scope="scope">
                <my-date :date="scope.row.createdAt"></my-date>
              </template>
            </el-table-column>
            <el-table-column label="Было" width="200px" :resizable="true">
              <template slot-scope="scope">
                <span>{{ $getEnumValue('OrderStateEnum', scope.row.oldState) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Стало" width="200px" :resizable="true">
              <template slot-scope="scope">
                <span>{{ $getEnumValue('OrderStateEnum', scope.row.newState) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Оператор" :resizable="true" prop="managerName" width="200px"/>
            <el-table-column label="Комментарий" :resizable="true" prop="comment"/>
          </el-table>
          <el-table v-else :data="editOrder.changes"
                    :default-sort="{prop: 'id', order: 'descending'}"
                    style="width: 100%;font-size: 16px"
                    height="100%"
                    :border="true"
                    v-ls-saver:changes
          >
            <el-table-column label="Дата" width="100" :resizable="true">
              <template slot-scope="scope">
                <my-date :date="scope.row.createdAt"></my-date>
              </template>
            </el-table-column>
            <el-table-column label="Оператор" :resizable="true" prop="managerName" width="200px"/>
            <el-table-column label="Действие" :resizable="true" prop="message"/>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane
          v-for="order in editOrder.orders.filter(o => !o.forDelete)"
          :label="orderTabName(order)"
          :name="order.id.toString()"
          :key="`ord_${order.id}`"
          style="height:100%"
          :ref="`ord_${order.id}`"
      >
        <primer-tab
            v-if="order.type === 'sst' || order.type === 'mst'"
            :metaorder="editOrder"
            :edit-order="order"
            :lock-manager="lockerState"
            :deliverys="deliverys"
            @send-to-erp="onSendToErpEvent"
            @set-deffered-urgent="onDefferedUrgentEvent"
            @order-state-change="onChangeStateEvent"
            @delete-order="onDeleteOrder"
            @set-primers-feedback="onStateOrderFeedback"
            :timestamp="timestamp"
        ></primer-tab>
        <product-tab
            v-if="order.type === 'pdt'"
            :metaorder="editOrder"
            :edit-order="order"
            :lock-manager="lockerState"
            :timestamp="timestamp"
            :ordersLots="ordersLots"
            :deliverys="deliverys"

            @order-state-change="onChangeStateEvent"
            @order-state-rollback="onRollbackStateEvent"
            @save-order="onBtnSaveClick"
            @reload-product-lots="loadOrdersLots"
            @delete-order="onDeleteOrder"

        ></product-tab>
        <seq-tab
            v-if="order.type === 'seq'"
            :metaorder="editOrder"
            :deliverys="deliverys"
            :edit-order="order"
            :lock-manager="lockerState"
            :timestamp="timestamp"
            :is-visible="activeTabName === order.id.toString()"
            :seq-store-primers="seqStorePrimers"
            :all-managers="allManagers"

            @delivered-to-evrogen="onDeliveredToEvrogenEvent"
            @order-state-change="onChangeStateEvent"
            @delete-order="onDeleteOrder"
            @set-active-tab="activeTabName=$event"
            @set-seq-option-operator-id = "onSetSeqOptionOperatorId"
        ></seq-tab>
        <service-tab
            v-if="order.type === 'srv'"
            :metaorder="editOrder"
            :deliverys="deliverys"
            :edit-order="order"
            :lock-manager="lockerState"
            :timestamp="timestamp"
            @set-active-tab="activeTabName=$event"
            @delivered-to-evrogen="onDeliveredToEvrogenEvent"
            @order-state-change="onChangeStateEvent"
            @delete-order="onDeleteOrder"
        ></service-tab>
        <ngs-tab
            v-if="order.type === 'ngs'"
            :metaorder="editOrder"
            :edit-order="order"
            :lock-manager="lockerState"
            :timestamp="timestamp"
            :deliverys="deliverys"
            @delivered-to-evrogen="onDeliveredToEvrogenEvent"
            @order-state-change="onChangeStateEvent"
            @order-state-rollback="onRollbackStateEvent"
            @save-order="onBtnSaveClick"
            @delete-order="onDeleteOrder"
        ></ngs-tab>
      </el-tab-pane>
      <el-tab-pane :disabled="true" key="newOrderTab" name="newOrderButtonTab">
        <div slot="label" style="display: flex;flex-wrap: nowrap;justify-content: space-between;align-items: center">
          <el-dropdown trigger="click" @command="onClickNewOrder">
            <my-el-button :disabled="!lockerState || !lockerState.canEdit"
                          type="primary">Добавить подзаказ<i class="el-icon-arrow-down el-icon--right"></i>
            </my-el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="pdt">
                <div style="width: 200px">Продукты</div>
              </el-dropdown-item>
              <template v-if="!editOrder.isStock">
                <el-dropdown-item command="sst">Простые олигонуклеотиды</el-dropdown-item>
                <el-dropdown-item command="mst">Модифицированные олигонуклеотиды</el-dropdown-item>
                <el-dropdown-item command="seq">Секвенирование</el-dropdown-item>
                <el-dropdown-item command="srv">Сервис</el-dropdown-item>
                <el-dropdown-item command="ngs">NGS</el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>
          <el-tag v-if="isProductOrdersWithoutReserve" size="Medium" style="margin-left: 30px;font-size: 14px;background-color: red;color:white;">Проверьте складские остатки</el-tag>
          <el-tag v-if="isInvoiceSumNotEquals" size="Medium" style="margin-left: 30px;font-size: 14px;background-color: red;color:white;">Проверьте счета</el-tag>
        </div>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer" slot-scope="{lockerState}">
      <div style="display:flex;justify-content: space-between;width: 100%">
        <div>
          <my-el-button @click="onBtnDeleteMetaorder" delete></my-el-button>
        </div>
        <div>
          <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
          <my-el-button :disabled="(!lockerState || !lockerState.canEdit)" @click="doSaveOrder" type="success">Сохранить</my-el-button>
          <my-el-button :disabled="(!lockerState || !lockerState.canEdit)" @click="onBtnSaveAndCloseClick()" type="success">Сохранить и закрыть</my-el-button>
        </div>
      </div>
      <change-monitor name="orderEditor" :items="monitoringItems" @change="onChanges" ref="changeMonitor"></change-monitor>
    </template>
  </component-window>
</template>

<script>
import _ from 'lodash';
import { alert, confirm, promptDate } from '@/components/common/dialogs/dialogUtils';

import clientEditor from '@/components/usersClientsAffiliation/ClientEditor';
import userEditor from '@/components/usersClientsAffiliation/UserEditor';
import affiliationEditor from '@/components/usersClientsAffiliation/AffiliationEditor';

import primerTab from '@/components/order/PrimerTab';
import productTab from '@/components/order/ProductTab';
import deliveryTab from '@/components/order/DeliveryTab';
import seqTab from '@/components/order/SeqTab';
import serviceTab from '@/components/order/ServiceTab';

import mailEditor from '@/components/mailsAndTemplates/MailEditor';
import { createNewSubOrder, removeOrder } from '@/utils/orders';
import tableSaver from '@/utils/tableSaver';
import payTab from './PayTab';
import myComment from '@/components/common/myComment';
import fileTable from '@/components/common/fileTable';
import ChangeMonitor from "@/components/common/changeMonitor";
import JsonViewer from "@/components/order/JsonViewer";
import contractsView from "@/components/usersClientsAffiliation/contractsView";
import MyClientDebtWarning from "@/components/common/myClientDebtWarning";
import NgsTab from "@/components/order/NgsTab";

export default {
  name: 'OrderEditor',
  components: {
    NgsTab,
    MyClientDebtWarning,
    ChangeMonitor,
    payTab,
    primerTab,
    productTab,
    deliveryTab,
    seqTab,
    serviceTab,
    fileTable,
    myComment,
    contractsView
  },
  mixins: [tableSaver],
  props: ['id', 'idType', 'user', 'client', 'onClose', 'subOrderId', 'isTabView'],
  provide: function() {
    return {
      sendLinkMail: this.sendLinkMail,
      reloadOrder: this.onReloadOrder,
      turnWatch: this.turnWatch
    }
  },

  data() {
    return {
      allManagers:[],
      seqStorePrimers: [],
      showUserFiles: false,
      timestamp: '',   //для перерисовки таблиц после перегрузки заказов - ,а то таблицы которые были пустые а стали с элементами глючат
      lockerIsWatch: false,
      windowSize: { minHeight: 400, minWidth: 400, height: '95%', width: '95%' },
      activeTabName: 'fields',
      users: [],
      userClients: [],
      clientAddresses: [],
      modifiers: [],
      editOrder: {
        orders: [],
        number: '',
        user: null,
        client: null,
        currency: 'rub',
        billingAffiliation: null,
        billingAffiliationId: null,
        files: [],
        payState: 'notPaid',
        deliverySum: 0,
        ordersSum: 0,
        prepaymentPercent: 0,
        paySum: 0,
        payCommission: 0,
        payType: 'payLater',
        tagRls: []
      },
      isValid: false,
      stateLogSelector: null,
      deliverys: [],
      otherDeliverys: [],
      ordersLots: [],
      selectedTag: null,
      rules: {
        user: [this.$validateRuleRequired],
        client: [this.$validateRuleRequired],
        billingAffiliation: [this.$validateRuleRequired]
      },
      isDeliveryLoading: false,
      tags: null,
      savePaneIndex: null,
      rawOrder: null
    };
  },
  watch: {
    allOrdersSum(value) {
      this.lockerIsWatch = false;
      this.editOrder.ordersSum = value;
      this.$nextTick(() => this.lockerIsWatch = true);
    },
    allDeliveriesSum: {
      handler: function(value) {
        this.lockerIsWatch = false;
        this.editOrder.deliverySum = value;
        this.$nextTick(() => this.lockerIsWatch = true);
      },
      immediate: true
    }
  },

  async mounted() {

    let loadFn = [
      async() => {
        this.tags = await this.$store.dispatch('metaorderTags/loadItems', {});
        if (this.editOrder.tagRls.length > 0) {
          this.selectedTag = this.tags.find(t => t.id === this.editOrder.tagRls[ 0 ].tagId);
        }
      },
      async() => this.allManagers = (await this.$store.dispatch('managers/loadItems')).filter(m => !m.isDisabled)
    ];

    if (!this.id || this.id == 0) {
      if (this.user) {
        this.editOrder.userId = this.user.id;
        this.editOrder.user = this.user;
        loadFn.push(async() => {
          await this.loadClientsForCurrentUser();
          if (this.userClients.length === 1) {
            this.setOrderDefaults(this.userClients[ 0 ])
          }
        });
      }
      if (this.client) {
        this.setOrderDefaults(this.client)
        loadFn.push(async() => {
          await this.loadUsersForCurrentClient();
          if (this.users.length === 1) {
            this.editOrder.user = this.users[ 0 ];
            this.editOrder.userId = this.users[ 0 ].id;
          }
        });
      }
    } else {
      await this.loadOrderAndDelivery(this.id);
      loadFn.push(this.loadUsersForCurrentClient);
      loadFn.push(this.loadClientsForCurrentUser);
      loadFn.push(this.loadOrdersLots);
      loadFn.push(this.loadOrderRawJson);

      if (this.editOrder.changes?.length) {
        this.stateLogSelector = "";
      }

      if (this.editOrder.orders.length > 0) {
        this.stateLogSelector = this.editOrder.orders[ 0 ].id.toString();
      }
    }
    loadFn.push(this.loadSeqStorePrimersIfNeed)


    if (this.subOrderId) {
      this.activeTabName = this.subOrderId.toString();
    } else if (this.idType === 'subNumber') {
      this.activeTabName = this.editOrder.orders.find(o => o.number === this.id).id.toString();
    }
    (loadFn.length > 0) && await Promise.all(loadFn.map(fn => fn()));

    if (this.$route.hash) {
      this.activeTabName = this.$route.hash.substring(1);
    }
    if (this.isTabView) {
      document.title = this.editOrder.number;
    }

    this.$watch('editOrder.user', this.onUserWatch);
    this.$watch('editOrder.client', this.onClientWatch);
    this.$watch('editOrder.billingAffiliation', this.onAffiliationWatch);
  },

  methods: {
    async restoreArchiveFiles() {
      let result = await promptDate('Не убирать в архив до', new Date());
      if (result.cancel) {
        return;
      }
      await this.$store.dispatch('metaorders/restoreArchiveFiles', {orderId: this.editOrder.id, date: result.date} );
      await this.loadOrderAndDelivery(this.editOrder.id);
    },

    prepareDeliveryToSend() {
      let deliveries = _.cloneDeep(this.deliverys);
      deliveries.forEach(d => {
        d.ordersRl.forEach(rl => {
          rl.delivery = null;   //Если не обнулить - axios наедается - видимо на циклической зависимости
          rl.order = null;     //Если order из другого заказа - то он получен через findDeliverys - а там он обрезанный, да и ваще не нужен в бэкэнде,
        });
      });
      return deliveries;
    },

    async loadOrderAndDelivery(orderId) {
      this.lockerIsWatch = false;
      try {
        ({ metaorder: this.editOrder, deliveries: this.deliverys } = await this.$store.dispatch('metaorders/loadItem', orderId));
        this.linkDeliverysToOrders();
        this.editOrder.deliverySum = this.allDeliveriesSum;
        this.timestamp = new Date();
      } catch (ex) {
        await alert('Ошибка загрузки заказа: ' + ex.message);
      } finally {
        this.$nextTick(() => {
          this.lockerIsWatch = true;
        });
      }
    },

    async saveOrderWrap(saveFn) {
      if (!await this.canSaveOrder()) {
        return false;
      }

      this.lockerIsWatch = false;
      try {
        let deliveries = this.prepareDeliveryToSend();
        //Сохраняем индекс вкладки, т.к. если есть новые подзаказы то после перегрузки название вкладки изменится
        this.savePaneIndex = this.$refs.tabs.panes.map(p => p.name).indexOf(this.activeTabName);

        let payload = { order: this.editOrder, deliveries };
        await saveFn(payload);

        this.linkDeliverysToOrders();
        if (this.savePaneIndex || this.savePaneIndex === 0) {
          this.$nextTick(() => {
            this.activeTabName = this.savePaneIndex === -1 ? 'fields' : this.$refs.tabs.panes[ this.savePaneIndex ]?.name;
            this.savePaneIndex = null;
          });
        }
        await this.$refs.compWindow.unlock();
        await this.loadOrdersLots();
      } catch (ex) {
        if (ex.code === 'reload') {
          if (await confirm('Не удается сохранить заказ. Кто-то успел сохранить заказ (или доставку заказа) быстрее. <br/><br/>Обновить заказ?')) {
            await this.onReloadOrder()
          }
        } else {
          await alert(ex.message);
        }
        return false;
      } finally {
        this.lockerIsWatch = true;
      }
      return true;
    },

    async doSaveOrder(withReload = true) {
      return await this.saveOrderWrap(
          async(orderAndDeliveries) => {
            let result = await this.$store.dispatch('metaorders/saveItem', orderAndDeliveries);
            if (withReload) {
              ({ deliveries: this.deliverys, metaorder: this.editOrder } = result);
            }
          }
      );
    },

    async onBtnAccountPay(isPaid) {
      return await this.saveOrderWrap(
          async(orderAndDeliveries) => {
            orderAndDeliveries.isPaid = isPaid;
            ({ deliveries: this.deliverys, metaorder: this.editOrder } =
                await this.$store.dispatch('metaorders/payFromAccount', orderAndDeliveries));
          }
      );
    },

    async onSendToErpEvent(orderId, IsForceSendToErp = false) {
      return await this.saveOrderWrap(
          async(orderAndDeliveries) => {
            let payload = Object.assign(orderAndDeliveries, { IsForceSendToErp, orderId });
            try {
              ({ deliveries: this.deliverys, metaorder: this.editOrder } = await this.$store.dispatch('metaorders/sendOrderToErp', payload));
            } catch (ex) {
              if (ex.message.indexOf('Попытка передать в ERP дубликат заказа') >= 0) {
                if (await confirm("Не удалось отправить в ERP: " + ex.message + "<br/>Отправить в ERP дубликат?")) {
                  await this.onSendToErpEvent(orderId, true);
                }
              } else {
                throw { message: "Не удалось отправить в ERP: " + ex.message };
              }
            }
          }
      );
    },

    async onDefferedUrgentEvent(data) {
      let saveOrderIdx = this.editOrder.orders.findIndex(o => o.id === data.orderId);
      let result = await this.saveOrderWrap(
          async(orderAndDeliveries) => {
            let payload = Object.assign(orderAndDeliveries, { DefferedDto: data });
            ({ deliveries: this.deliverys, metaorder: this.editOrder } = await this.$store.dispatch('metaorders/setDefferedOrUrgent', payload));
          });
      if (!result) {
        return false;
      }
      if (data.setApproveState) {
        let order = this.editOrder.orders[saveOrderIdx];
        await this.onChangeStateEvent({orderId: order.id, newState: 'awaitApprove'});
      }
      return true;
    },

    async onChangeStateEvent({ orderId, newState }) {
      await this.saveOrderWrap(
          async(orderAndDeliveries) => {
            let payload = Object.assign(orderAndDeliveries, { orderId, newState });
            ({ deliveries: this.deliverys, metaorder: this.editOrder } = await this.$store.dispatch('metaorders/setOrderState', payload));
          });
    },

    async onStateOrderFeedback(orderId) {
      await this.saveOrderWrap(
          async(orderAndDeliveries) => {
            let payload = Object.assign(orderAndDeliveries, { orderId});
            ({ deliveries: this.deliverys, metaorder: this.editOrder } = await this.$store.dispatch('metaorders/setPrimersFeedback', payload));
          });
    },







    async onRollbackStateEvent({ orderId, newState }) {
      await this.saveOrderWrap(
          async(orderAndDeliveries) => {
            let payload = Object.assign(orderAndDeliveries, { orderId, newState });
            ({ deliveries: this.deliverys, metaorder: this.editOrder } = await this.$store.dispatch('metaorders/rollbackOrderState', payload));
          });
    },

    async onSetSeqOptionOperatorId(payload) {
      await this.saveOrderWrap(
          async (orderAndDeliveries) => {
            orderAndDeliveries.orderId = payload.orderId;
            orderAndDeliveries.option = payload.option;
            orderAndDeliveries.operatorId = payload.operatorId;
            ({ deliveries: this.deliverys, metaorder: this.editOrder } = await this.$store.dispatch('metaorders/setSeqOptionOperatorId', orderAndDeliveries));
          });
    },



    isSeqOrderOnPlate(order) {
      return order.type === 'seq' && order.elements.some(el => el.primersRls.some(rl => rl.plateId !== null));
    },

    async onDeleteOrder(orderId) {
      if (!await confirm(`Заказ будет удален. Продолжить?`)) {
        return;
      }
      if (!await confirm(`Вся информация о заказе потеряется. Точно продолжить?`)) {
        return;
      }

      if (parseInt(orderId) < 0) {
        removeOrder(orderId * 1, this.editOrder, this.deliverys);
        let currentTabIdx = this.$refs.tabs.panes.map(p => p.name).indexOf(this.activeTabName);
        this.activeTabName = this.$refs.tabs.panes[ currentTabIdx - 1 ]?.name;
      } else {
        let deletedOrder = this.editOrder.orders.find(o => o.id === orderId);
        if (this.isSeqOrderOnPlate(deletedOrder) && !await confirm('Есть элементы на плашках. Продолжить удаление?')) {
          return;
        }

        await this.saveOrderWrap(
            async(orderAndDeliveries) => {
              let payload = Object.assign(orderAndDeliveries, { orderId });
              ({ deliveries: this.deliverys, metaorder: this.editOrder } = await this.$store.dispatch('metaorders/deleteOrder', payload));
              this.savePaneIndex = -1;
            });
      }
    },

    async onDeliveredToEvrogenEvent({ orderId }) {
      return await this.saveOrderWrap(
          async(orderAndDeliveries) => {
            let payload = Object.assign(orderAndDeliveries, { orderId });
            ({ deliveries: this.deliverys, metaorder: this.editOrder } = await this.$store.dispatch('metaorders/setDeliveredToEvrogen', payload));
          });
    },

    linkDeliverysToOrders() {
      this.deliverys.forEach(d =>
                                 d.ordersRl.forEach(rl => {
                                                      let fndOrder = _.find(this.editOrder.orders, o => o.id === rl.order.id);
                                                      rl.order = fndOrder ? fndOrder : rl.order;
                                                    }
                                 )
      )
    },

    async onBtnSaveAndCloseClick() {
      if (!await this.doSaveOrder(false)) {
        return;
      }
      if (this.isTabView) {
        window.close();
      } else {
        this.onClose && this.onClose(this.editOrder);
        this.$emit('close', this.editOrder);
      }
    },

    async onBtnCancelClick() {
      if (this.$refs[ "compWindow" ].lockState.isEditNow
          && !await confirm("Есть несохраненные изменения. Закрыть без сохранения?")) {
        return;
      }
      if (this.isTabView) {
        window.close();
        return;
      }
      this.onClose && this.onClose(this.editOrder);
      this.$emit('close');
    },


    //***********************************************************************************
    async loadSeqStorePrimersIfNeed() {
      if (!this.editOrder.client || this.editOrder.orders.every(o => o.type !== 'seq')) {
        return;
      }
      let query = [
        { fieldName: 'userId', op: 'eq', values: [null] },
        { fieldName: 'clientId', op: 'eq', values: [this.editOrder.clientId] }
      ];
      this.seqStorePrimers = await this.$store.dispatch('primerStores/loadItems', { query });
    },

    async onChanges(data) {
      this.lockerIsWatch = false;
      ['user', 'client', 'affiliation'].forEach(field => {
        if (!data[ field ]?.update?.length) {
          return;
        }
        let addFldName = field === 'affiliation' ? 'billingAffiliation' : field;

        let object = data[ field ]?.update?.find(upd => upd.id === this.editOrder[ `${addFldName}Id` ]);
        if (object) {
          this.editOrder[ `${addFldName}` ] = object;
          this.$refs.changeMonitor.animateElements([
                                                     this.$refs[ `${field}Selector` ].$el,
                                                     this.$refs[ `${field}Selector` ].$el.querySelector('input')
                                                   ]);
        }

        if (field === 'affiliation') {
          return;
        }
        addFldName = field === 'user' ? 'recipient' : field;
        let deliverys = this.deliverys.filter(d => data[ field ].update.some(upd => upd.id === d[ `${addFldName}Id` ]));
        deliverys.forEach(d => d[ addFldName ] = data[ field ].update.find(upd => upd.id === d[ `${addFldName}Id` ]));
        let elements = deliverys.map(d =>
                                         document.getElementById(`forChanges_${field}_${d.id}`)).filter(el => el);
        elements.push(...elements.map(el => el.querySelector('input')));
        this.$refs.changeMonitor.animateElements(elements);
      });
      this.turnWatch(true);

    },

    canSaveOrder() {
      if (this.editOrder.orders.every(o => o.state === 'done') && !this.editOrder.client) {
        this.activeTabName = 'fields';
        alert('Поле клиент должно быть заполнено');
        return false;
      }



      let errOrder = this.editOrder.orders.find(order => this.$refs[ `ord_${order.id}` ][ 0 ].$children[ 0 ].error);
      if (errOrder) {
        alert(this.$refs[ `ord_${errOrder.id}` ][ 0 ].$children[ 0 ].error)
        this.activeTabName = errOrder.id.toString();
        return false;
      }
      return true;
    },

    onChangeReclamation(value) {
      let tag = this.editOrder.tagRls.find(rl => rl.tagId === 2);
      if (value) {
        if (tag) {
          tag.forDelete = false;
        } else {
          this.editOrder.tagRls.push({ tagId: 2, forDelete: false });
        }
      } else {
        if (tag) {
          tag.forDelete = true;
        }
      }
    },

    summaryRowClassName({ row }) {
      return row.title ? 'summary-row' : '';
    },

    turnWatch(value) {
      if (value) {
        this.$nextTick(() => this.lockerIsWatch = value);
      } else {
        this.lockerIsWatch = value;
      }
    },

//<editor-fold desc="common"> --------------------------------------------------------------------------------------------
    async loadOrderRawJson() {
      this.rawOrder = await this.$store.dispatch('metaorders/getRawOrder', JSON.stringify(this.editOrder.number));
    },
    async showRowOrder() {
      await this.$showWindowAsync(JsonViewer, {object: this.rawOrder});
    },

    async loadOrdersLots(callback) {
      let cats = this.editOrder.orders
      .filter(o => o.type === "pdt")
      .reduce((acc, ord) => acc.concat(ord.elements.map(e => `${e.product.id}:${e.product.cat}`)), []);
      try {
        if (cats && cats.length > 0) {
          this.ordersLots = await this.$store.dispatch('productLots/getExchCatInfo', cats);
        }
      } catch (ex) {
        this.ordersLots = null;
        await alert('Ошибка получения складских остатков.' + ex.message);
      }
      callback && callback();
    },

    orderTabName(order) {
      let result = order.number;
      if (!result) {
        result = 'Новый заказ';
      }
      if (result.toLowerCase().indexOf(order.type.toLowerCase()) < 0) {
        result += ` (${order.type.toUpperCase()})`;
      }
      return result;
    },

    onClickNewOrder(orderType) {
      let order = createNewSubOrder(this.editOrder, this.deliverys, this.$settings.deadlines, orderType);
      this.activeTabName = order.id.toString();
      return order;
    },

    async onBtnDeleteMetaorder() {
      let errOrder = this.editOrder.orders.find(order => order.type === 'pdt' && order.elements.some(el => el.lots.length > 0));
      if (errOrder) {
        await alert("Для удаления заказы нуно отвязать все лоты.")
        this.activeTabName = errOrder.id.toString();
        return;
      }

      if (!await confirm(`Заказ будет удален. Продолжить?`)) {
        return;
      }
      if (!await confirm(`Вся информация о заказе потеряется. Точно продолжить?`)) {
        return;
      }
      if (this.editOrder.orders.length > 0) {
        if (!await confirm(`А еще уничтожены будут подзаказы: ${this.editOrder.orders.map(o => o.number).join(', ')}. Вы этого хотите?`)) {
          return;
        }
      }
      if (this.editOrder.orders.some(o => this.isSeqOrderOnPlate(o))) {
        if (!await confirm(`И еще забыл сказать - есть секвенсовые заказы на плашке. Точно продолжить?`)) {
          return;
        }
      }

      try {
        await this.$store.dispatch('metaorders/deleteItem', this.editOrder);
      } catch (ex) {
        await alert(ex.message);
        return;
      }
      if (this.isTabView) {
        window.close();
      } else {
        this.onClose && this.onClose(this.editOrder);
        this.$emit('close');
      }
    },


    onClickTabHeader(tab) {
      this.savePaneIndex = null;
      this.activeTabName = tab.name;
    },


    async onReloadOrder() {
      await this.loadOrderAndDelivery(this.editOrder.id);
      await this.loadOrdersLots();
    },


    async onBtnSaveClick(callback) {
      let order = await this.doSaveOrder();
      if (order) {
        callback && callback(order, this.deliverys);
      }
    },

    calcSubOrderPrice(subOrder) {
      return subOrder.useHandPrice
          ? subOrder.handPrice
          : (subOrder.discountPercent ? subOrder.discountPrice : subOrder.price);
    },


//</editor-fold>

//<editor-fold desc="mails"> ----------------------------------------------------------------------------------------------

    async showMailEditor(mail) {
      await this.$showWindowAsync(mailEditor, { mail, mode: 'view', actionBeforeSend: null,
        noFilesMessage: this.editOrder.isArchive
            ? 'Для просмотра файлов сначала нужно во вкладке "Файлы"  достать файлы из архива'
            :''
      });
    },
//</editor-fold>

//<editor-fold desc="fields"> ----------------------------------------------------------------------------------------------
    async onAffiliationWatch(newAffiliation) {
      this.editOrder.billingAffiliationId = newAffiliation?.id;
    },

    async onUserWatch(newUser, oldUser) {
      if (!newUser) {
        this.editOrder.userId = null;
        this.userClients = [];
        this.editOrder.client = null;
        this.editOrder.clientId = null;
        this.editOrder.billingAffiliation = null;
        this.editOrder.billingAffiliationId = null;

      } else if (newUser.id !== oldUser?.id) {

        this.editOrder.userId = newUser.id;
        this.userClients = await this.loadClientsForCurrentUser(newUser.id);

        if (!this.editOrder.id || this.lockerIsWatch) {
          this.editOrder.client = this.userClients?.length === 1 ? this.userClients[ 0 ] : null;
          this.editOrder.clientId = this.userClients?.length === 1 ? this.userClients[ 0 ].id : null;

          this.deliverys
          .filter(dlv => !dlv.recipientId)
          .forEach(dlv => {
            dlv.recipient = newUser;
            dlv.recipientId = newUser.id;
          });
        }
      }
    },

    async onClientWatch(newClient, oldClient) {
      if (this.editOrder.id > 0 && !this.lockerIsWatch) return;

      if (!newClient) {
        this.editOrder.clientId = null;
        this.editOrder.billingAffiliation = null;
        this.editOrder.billingAffiliationId = null;

      } else if (newClient.id !== oldClient?.id) {
        this.setOrderDefaults(newClient);
        this.loadSeqStorePrimersIfNeed();

        if (newClient.billingAffiliationsRl.length === 1) {
          this.editOrder.billingAffiliation = newClient.billingAffiliationsRl[ 0 ].affiliation;
          this.editOrder.billingAffiliationId = this.editOrder.billingAffiliation.id;
        } else if (newClient.billingAffiliationsRl.length === 0) {
          this.editOrder.billingAffiliation = newClient.affiliation;
          this.editOrder.billingAffiliationId = newClient.affiliationId;
        } else {
          this.editOrder.billingAffiliation = null;
          this.editOrder.billingAffiliationId = null;
        }

        this.deliverys
        .filter(dlv => !dlv.clientId)
        .forEach(dlv => {
          dlv.client = newClient;
          dlv.clientId = newClient.id;
          dlv.address = newClient.addresses.length === 1 ? newClient.addresses[ 0 ] : null;
          dlv.addressId = newClient.addresses.length === 1 ? newClient.addresses[ 0 ].id : null;
        });
      }
    },

    async loadUsersForCurrentClient() {
      if (this.editOrder.client) {
        const query = [{ fieldName: '@clientUserRls.clientId', op: 'eq', values: [this.editOrder.client.id] }];
        this.users = await this.$store.dispatch('users/loadItems', { query });
      }
    },

    setOrderDefaults(newClient) {
      this.editOrder.client = newClient;
      this.editOrder.clientId = newClient.id;
      this.editOrder.currency = newClient.currency;
      this.editOrder.prepaymentPercent = newClient.defaultPrepaymentPercent;
      this.editOrder.payType = newClient.currency !== 'rub'
          ? 'fromInvoice'
          : (newClient.defaultPayType || 'payLater');
    },


    async loadClientsForCurrentUser(userId) {
      if (!userId) {
        userId = this.editOrder?.user?.id;
      }
      if (!userId) {
        this.userClients = [];
      } else {
        const query = [{ fieldName: '@clientUserRls.userId', op: 'eq', values: [this.editOrder.user.id] }];
        this.userClients = await this.$store.dispatch('clients/loadItems', { query });
      }
      return this.userClients;
    },

    onAutoCompleteUsers(search, callback) {
      if (this.users) {
        callback(this.users)
      }
    },

    async onAutocompleteClientSearch(query, cb) {
      cb(this.userClients);
    },

    onAutocompleteAffiliationSearch(query, cb) {
      if (this.editOrder.client) {
        let result = this.editOrder.client.billingAffiliationsRl.map(rl => rl.affiliation);
        if (this.editOrder.client.affiliation
            && !this.editOrder.client.billingAffiliationsRl.some(rl => rl.affiliationId === this.editOrder.client.affiliation.id)) {
          result.push(this.editOrder.client.affiliation);
        }
        cb(result);

      } else {
        cb([])
      }
    },

    async onEditUser(id) {
      let user = await this.$showWindowAsync(userEditor, { id });
      if (user && id > 0) {
        this.editOrder.user = user;
      }
    },

    async onEditClient(id) {
      let client = await this.$showWindowAsync(clientEditor, { id, addUser: this.editOrder.user });
      if (client) {
        this.loadClientsForCurrentUser();
        this.editOrder.client = client;
      }
    },

    async onEditBillingAffiliation(id) {
      let affiliation = await this.$showWindowAsync(affiliationEditor, { id, addBillingClient: this.editOrder.client });
      if (affiliation) {
        this.editOrder.billingAffiliation = affiliation;
        this.editOrder.billingAffiliationId = affiliation.id;
        if (id === 0) {
          affiliation.billingClientsRl = null;
          this.editOrder.client.billingAffiliationsRl.push({
                                                             affiliationId: affiliation.id,
                                                             affiliation: affiliation
                                                           })
        }
      }
    },
//</editor-fold>

//<editor-fold desc="files">
    addFilesToOrder(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      if (!fileList.length) {
        return;
      }
      for ( let i = 0; i < fileList.length; i++ ) {
        let fileName = fileList[ i ].name;
        if (this.editOrder.files.some(r => r.name.toLowerCase() === fileName.toLowerCase())) {
          continue;
        }
        const fReader = new FileReader();
        fReader.onload = () => {
          this.editOrder.files.push({
                                      id: 0,
                                      type: 'managerFiles',
                                      name: fileList[ i ].name,
                                      orderId: this.editOrder.id,
                                      dataInBase64: btoa(fReader.result),
                                      description: ''
                                    })
        };
        fReader.readAsBinaryString(fileList[ i ])
      }
    },
//</editor-fold>
    deliveryHeader() {
      if (!this.deliverys) {
        return '';
      }

      let result = ((this.deliverys.length || 0) > 1) ? `Доставки (${this.deliverys.filter(d => !d.forDelete).length})` : 'Доставка';
      if (this.deliverys.every(d => d.woDelivery)) {
        if ( this.deliverys.every(d => d.type === 'reverse')) {
          return result + ' - самоввоз';
        }
        return result + ' - самовывоз';
      }
      if (this.deliverys.every(d => !d.woDelivery)) {
        if (this.deliverys.every(d => d.addressId && d.serviceId && (d.ordersRl.some(rl => rl.price !== null && rl.price !== undefined)))) {
          if (this.deliverys.length === 1 ) {
            result += ' ' + this.deliverys[0].service.name +', '
                + this.deliverys[0].ordersRl.reduce((acc, rl) => acc + rl.price, 0)
                + this.$getEnumValue('CurrencyEnum', this.editOrder.currency);
          }
          return result + ' <i class="el-icon-check" style="color: green; font-weight: bold;"></i>';
        }
      }
      return result;
    },

  },

  computed: {
    canSetReclamation() {
      return this.editOrder.payState === 'notPaid'
        || this.editOrder.payType === 'fromAccount';
    },

    activeContracts() {
      return this.editOrder.billingAffiliation?.contracts.filter(c => c.isActive) || [];
    },

    monitoringItems() {
      return {
        user: {
          actions: ['update'],
          ids: [...(new Set([this.editOrder.userId].concat(...this.deliverys.filter(d => d.userId).map(d => d.userId))))],
          query: null,
          insertExist: false
        },
        client: {
          actions: ['update'],
          ids: [...(new Set([this.editOrder.clientId].concat(...this.deliverys.filter(d => d.clientId).map(d => d.clientId))))],
          query: null,
          insertExist: false
        },
        affiliation: {
          actions: ['update'],
          ids: [this.editOrder.billingAffiliationId],
          query: null,
          insertExist: false
        }
      }
    },

    isProductOrdersWithoutReserve() {
      return this.editOrder.orders.some(o =>
                                            o.type === 'pdt'
                                            && o.elements.some(el => {
                                                                 if (el.lots.length > 0) {
                                                                   return false;
                                                                 }
                                                                 let catRow = this.ordersLots?.find(ol => (ol.cat === el.product?.cat || ''));
                                                                 if (!catRow) {
                                                                   return false;
                                                                 }
                                                                 return (catRow.stockCount - catRow.reservedInOrders) < 0;
                                                               }
                                            )
      );
    },

    isInvoiceSumNotEquals() {
      return (this.editOrder.invoiceRls?.length || 0) > 0
          && this.editOrder.invoiceRls?.every(rl => rl.invoice.metaorderRls.length === 1)
          && this.editOrder.invoiceRls?.reduce((acc, rl) => acc + rl.invoice.sum, 0)?.toFixed(2) !== (this.editOrder.ordersSum + this.editOrder.deliverySum).toFixed(2);
    },

    orderIsReclamation() {
      return this.editOrder.tagRls.some(rl => rl.tagId === 2 && !rl.forDelete);
    },

    payTabLabel() {
      return `Оплата - ${this.$getEnumValue('PayTypeEnum', this.editOrder.payType)}`;
    },
    fileTabLabel() {
      let count = this.editOrder.files?.filter(f => !f.forDelete)?.length || 0;
      return `Файлы${count > 0 ? ` (${count})` : ''}`;
    },


    clientAffiliation() {
      return this.editOrder.client?.affiliation?.name || '';
    },

    clientAffiliationComment() {
      return this.editOrder.client?.affiliation?.importantComment || '';
    },

    sequencePrices() {
      return this.$store.state.prices.sequencePrices;
    },

    userMails() {
      if (this.editOrder.user && this.editOrder.user.contacts) {
        return this.editOrder.user.contacts.filter(r => r.type === 'mail').map(r => r.contact).join(",&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;");
      }
      return "";
    },
    userPhones() {
      if (this.editOrder.user && this.editOrder.user.contacts) {
        return this.editOrder.user.contacts.filter(r => r.type === 'phone').map(r => r.contact).join(",&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;");
      }
      return "";
    },

    userFiles() {
      return this.editOrder.files
          ? this.editOrder.files.filter(r => r.type === 'userFiles')
          : [];
    },
    managerFiles() {
      return this.editOrder.files
          ? this.editOrder.files.filter(r => r.type === 'managerFiles')
          : [];
    },

    allPrimers() {
      if (!this.editOrder) {
        return [];
      }
      let result = [];
      [this.editOrder.sstOrder, this.editOrder.mstOrder].forEach(order => {
        if (order && order.primers.length > 0) {
          Array.prototype.push.apply(result, order.primers);
        }
      });
      return result;
    },

    orders() {
      return [this.editOrder.sstOrder, this.editOrder.mstOrder, this.editOrder.pdtOrder].filter(o => !!o);
    },

    orderLog() {
      let order = _.find(this.editOrder.orders, o => o.id == this.stateLogSelector);
      if (order && order.stateLog && order.stateLog.length > 0) {
        return order.stateLog
      }
      return [];
    },
    orderTitle() {
      return (!this.id && !this.editOrder.id)
          ? 'Новый заказ'
          : `Редактирование заказа ${this.editOrder && this.editOrder.number ? this.editOrder.number : ''}`;

    },

    allOrdersSum() {
      return this.editOrder.orders.reduce((sum, o) => sum + this.calcSubOrderPrice(o), 0);
    },

    allDeliveriesSum() {
      return this.deliverys
      .filter(d => d.calcDeliveryPrice)
      .reduce((acc, delivery) =>
                  delivery.ordersRl
                  .filter(rl => this.editOrder.orders.some(o => o.id === rl.orderId))
                  .reduce((acc, rl) => acc + rl.price * 1, acc), 0);
    },
    ordersView() {
      let result = this.editOrder.orders.map(o => o);

      result.push({ title: 'Сумма', value: this.$roundFmt(this.editOrder.ordersSum) });
      result.push({ title: 'Доставка', value: this.$roundFmt(this.editOrder.deliverySum) });
      result.push({ title: 'Итого', value: this.$roundFmt(this.editOrder.ordersSum + this.editOrder.deliverySum) });
      return result;
    }
  }
}
</script>

<style>
.el-table .summary-row {
  background-color: #F5F7FA;
}

.primer-modifier-div {
  background-color: #85d0ff;
  font-family: Tahoma, Arial, Verdana;
  font-size: 11px;
  text-decoration: none !important;
  white-space: nowrap;
  display: inline;
}
</style>
