import mailEditor from "@/components/mailsAndTemplates/MailEditor";

export default {
    name: "BaseOrderTab",
    props: ['deliverys'],
    inject: ['reloadOrder', 'turnWatch'],
    methods: {
        async sendNewOrderMail(metaorder, templateName, addParam) {
            let mail = await this.$mailUtils.buildNewOrderMail(metaorder, this.deliveries || this.deliverys, templateName, addParam);
            let accountType = this.$mailUtils.calcAccountTypeForMetaorder(metaorder);
            
            mail = await this.$showWindowAsync(mailEditor, {
                mail,
                mode: 'send',
                accountType,
                noFilesMessage: metaorder.isArchive
                    ? 'Для работы с файлами сначала нужно во вкладке "Файлы"  достать файлы из архива'
                    : ''
            });
            if (mail) {
                this.lockerIsWatch = false;
                this.$nextTick(() => {
                    metaorder.mails.push(mail);
                    this.lockerIsWatch = true;
                });
            }
        },
    
        saveOrder() {
            return new Promise(resolve => {
                this.$emit("save-order", (order, deliverys) => resolve({ order, deliverys }));
            })
        },
        
        letterIsSend(name) {
            if (this.metaorder.mails && this.metaorder.mails.some(m => m.name && m.name.toLowerCase() === name.toLowerCase())) {
                return true;
            }
            if (['invoiceFor', 'newOrder'].includes(name)) {
                return this.metaorder.orders.some(ord =>
                                                      !ord.oldJson
                                                          ? false
                                                          : JSON.parse(ord.oldJson)[ name === 'invoiceFor' ? 'invoiceMailDate' : 'confirmMailDate' ]
                );
            }
            return false;
        }
    },
    computed: {
        checkOrderPay() {
            let mo = this.metaorder;
            if (mo.payType === 'payLater') {
                return true;
            }
            return mo.paySum + (mo.payCommission || 0) >= Math.round((mo.deliverySum + mo.ordersSum) / 100 * mo.prepaymentPercent);
        }
    }
}
