<template>
  <component-window
      :title="editContract.id ? 'Редактирование контракта' : 'Добавление контракта'"
      :show-refresh-button="true"
      @close="closeWindow"
      ref="compWindow"
      :maximize="true"
  >
    <div style="display: flex;flex-direction: column;height: 100%" >
      <el-form label-position="left" label-width="105px" :model="editContract" :rules="rules" ref="form">
          <div style="display: flex; align-items: stretch; height: 100%">
            <div style="display: flex; flex-direction: column; width: 70%">
              <el-form-item label="Наименование" prop="name">
                <el-input v-model="editContract.name"></el-input>
              </el-form-item>
              <el-form-item label="Поставщик" prop="owrAffiliation">
                <my-selector
                    type="affiliation"
                    v-model="editContract.owrAffiliation"
                    placeholder=""
                    @change="editContract.owrAffiliationId = editContract.owrAffiliation?.id"
                    :selector="onAutocompleteAffiliation"
                />
              </el-form-item>


              <el-form-item label="Дата" prop = "date">
                <el-date-picker
                    style="width: 150px;margin-right: 10px"
                    v-model="editContract.date"
                    type="date"
                    :editable="true"
                    default-time="00:00:00"
                    :picker-options="{firstDayOfWeek: 1}"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Активен">
                <el-switch v-model="editContract.isActive"></el-switch>
              </el-form-item>
            </div>
            <div style="display: flex; flex-direction: column; margin-left: 20px; width: 100%;height: 100% ">
             <el-form-item label-width="0" label-position="top">
               <div style="font-size: 14px;color: #606266;">Комментарий</div>
               <el-input type="textarea" v-model="editContract.comment" :autosize="{ minRows: 5, maxRows: 5}"  ></el-input>
             </el-form-item>
            </div>
          </div>
      </el-form>
        <div style="display: flex;flex-direction: column;flex-grow: 1">
          <file-table :files="editContract.files" with-date :file-type="['contract']"></file-table>
        </div>
      </div>
    <template slot="footer">
      <div style="display: flex;justify-content: space-between;width:100%">
        <div style="display: flex">
          <my-el-button delete
                        v-if="!!editContract.id"
                        @click="onBtnDeleteClick"
                        style="margin-right: 15px"></my-el-button>
        </div>
        <div style="display:flex">
          <my-el-button @click="closeWindow(null)" type="warning" align="right">Отменить</my-el-button>
          <my-el-button @click="onBtnSaveClick()" type="success">Сохранить</my-el-button>
        </div>
      </div>
    </template>
  </component-window>
</template>

<script>
import _ from 'lodash';
import FileTable from "@/components/common/fileTable";
import { confirm } from "@/components/common/dialogs/dialogUtils";
export default {
  name: "ContractEditor",
  components: { FileTable },
  props: {
    contract: {
      type: Object,
      default: null
    },
    onClose: {
      type: Function,
      default: null
    }
  },
  async mounted() {
    this.owrAffiliations = await this.$store.dispatch('affiliations/loadItems', { query: [ { fieldName: 'isEvrogen', op: 'eq', values: [ true ] } ] })
    if (this.contract) {
      this.editContract = _.cloneDeep(this.contract);
    }
  },

  data() {
    return {
      editContract: {id: 0, name: '', files: [], isActive: true, date: new Date(), owrAffiliation: null, owrAffiliationId: null },
      windowSize: { minHeight: 400, minWidth: 555, height: '80%', width: '555' },
      owrAffiliations: [],
      rules: {
        name: [
          { required: true, message: 'Введите название договора', trigger: 'blur' },
        ],
        date: [
          { required: true, message: 'Введите дату договора', trigger: 'blur' },
        ],
        owrAffiliation: [
          { required: true, message: 'Введите поставщика', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    closeWindow() {
      this.$emit('close');
    },

    async onBtnDeleteClick() {
      if (await confirm('Удалить договор?')) {
        this.editContract.forDelete = true;
        this.$emit('close', this.editContract);
        this.onClose && this.onClose(this.editContract);
      }
    },

    onBtnSaveClick() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('close', this.editContract);
          this.onClose && this.onClose(this.editContract);
        }
      });
    },

    onAutocompleteAffiliation(query, cb) {
      cb(this.owrAffiliations)
    },
  }
}
</script>

<style scoped>

</style>
