<template>
  <keep-alive>
    <trumbowyg id="myEditorId" v-if="templatesIsLoad" ref="editor" v-bind="$attrs" v-on="$listeners" :config="config()"></trumbowyg>
  </keep-alive>
</template>

<script>
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';
import '@/utils/trumbowyg.cleanpaste'
import jQuery from 'jquery';

export default {
  name: 'trumbowygWrapper',
  props: ['accountType'],
  components: { Trumbowyg },
  async mounted() {
    this.templates = this.$getEnum('mailShortTemplates').filter(t => t.types.split(',').includes(this.preparedAccountType));
    this.templatesIsLoad = true;
    this.$nextTick(() => {
      let element = document.getElementsByClassName('trumbowyg-box')[ 0 ];
      element.setAttribute("style", "display: flex;flex-direction: column;min-height: auto;");
      element = document.getElementsByClassName('trumbowyg-editor')[ 0 ];
      element.setAttribute("style", "height: 0px;flex-grow: 1;min-height: 0;");
      element = document.getElementsByClassName('trumbowyg-textarea')[ 0 ];
      element.setAttribute("style", "height: calc(100% - 36px);flex-grow: 1;position: absolute;top: 36px;min-height: 0;");

      let enterKeyPressHandler = function(evt) {
        var sel, range, br1, br;
        var addedBr = false;
        evt = evt || window.event;
        var charCode = evt.which || evt.keyCode;
        if (charCode * 1 === 13) {
          if (typeof window.getSelection != "undefined") {
            sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
              range = sel.getRangeAt(0);
              range.deleteContents();
              br1 = document.createElement("br");
              br = document.createElement("br");
              range.insertNode(br1);
              range.insertNode(br);
              range.setEndAfter(br);
              range.setStartAfter(br);
              sel.removeAllRanges();
              sel.addRange(range);
              addedBr = true;
            }
          } else if (typeof document.selection != "undefined") {
            sel = document.selection;
            if (sel.createRange) {
              range = sel.createRange();
              range.pasteHTML("<br>");
              range.select();
              addedBr = true;
            }
          }
          if (addedBr) {
            if (typeof evt.preventDefault != "undefined") {
              evt.preventDefault();
            } else {
              evt.returnValue = false;
            }
          }
        }
      }

      let editor = document.getElementsByClassName('trumbowyg-editor')[ 0 ];
      if (typeof editor.addEventListener != "undefined") {
        editor.addEventListener("keypress", enterKeyPressHandler, false);
      } else if (typeof editor.attachEvent != "undefined") {
        editor.attachEvent("onkeypress", enterKeyPressHandler);
      }
      editor.focus();
    });
  },

  beforeDestroy() {
    //иначе остается textaera от редактора
    let element = document.getElementById('myEditorId');
    element.setAttribute("style", "left:-1000px;height:0;width:0;display:none");
  },

  data() {
    return {

      templatesIsLoad: false,
      templates: [],
    }
  },

  computed: {
    preparedAccountType() {
      if (this.accountType === 'sst' || this.accountType === 'mst') {
        return 'prm';
      } else if (this.accountType === 'seq-result') {
        return 'seq';
      } else if (this.accountType === 'srv-result') {
        return 'srv'
      }
      return this.accountType;
    }
  },

  methods: {
    async insertText(text) {
      this.$refs.editor.el.trumbowyg('execCmd',
                                     {
                                       cmd: 'insertHtml',
                                       param: text,
                                       forceCss: true
                                     });
      await this.wait();
    },

    async wait() {
      return new Promise((resolve) => {
        setTimeout(() => resolve(), 1000)
      });
    },


    buildBtnDefs() {
      let result = {};
      this.templates.forEach(tmpl => {
                               result[ tmpl.name ] = {
                                 title: tmpl.name,
                                 hasIcon: false,
                                 fn: async() => await this.insertText(tmpl.template)
                               }
                             }
      );

      result[ 'Шаблон' ] = {
        dropdown: this.templates.map(tmpl => tmpl.name),
        hasIcon: false,
        title: 'Шаблон'
      }
      return result;
    },

    config() {
      let result = {
        autogrow: false,
        autogrowOnEnter: false,
        semantic: false,
      };

      if (this.preparedAccountType && this.templates.some(t => t.types.split(',').includes(this.preparedAccountType))) {
        result.btnsDef = this.buildBtnDefs();
        result.btns = [...jQuery.trumbowyg.defaultOptions.btns, ['Шаблон']]
      }
      return result;
    }
  }
}
</script>

<style scoped>

</style>
